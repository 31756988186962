import React from 'react';

const Segmento = () => {
  return (
    <section id='segmentos' className='segmentos'>
        <div className='content-segmentos'>

          <div className='card01-cover'>
            <div className='card01'>
              <div className='sub-card01'></div>
            </div>
            <div className='sub-card01-txt'>
              <h3 className='text-gold-seg'>Salão de beleza</h3>
              <p>Você que já participou dos cursos e workshops do nosso parceiro Lairton Rodrigues e ganhou a licença, temos uma condição especial, com variedades de benefícios

</p>
            </div>
            
          </div>
          <div className='card02-cover'>
            <div className='card02'>
              <div className='sub-card02'></div>
            </div>
            <div className='sub-card02-txt'>
              <h3 className='text-gold-seg'>Barbearia</h3>
              <p>Seus clientes podem reservar seus horários facilmente online, sem precisar ligar. Além disso, você tem controle total da sua agenda em tempo real, tudo pra otimizar seu tempo</p>
            </div>
            
          </div>
          <div className='card03-cover'>
            <div className='card03'>
              <div className='sub-card03'></div>
            </div>
            <div className='sub-card03-txt'>
              <h3 className='text-gold-seg'>Ótica</h3>
              <p>Nosso sistema garante que sua ótica esteja sempre cheia, com horários perfeitamente organizados e sem falhas, seus clientes nunca perderão uma consulta. Resultado? Mais vendas </p>
            </div>
            
          </div>
          <div className='card04-cover'>
            <div className='card04'>
              <div className='sub-card04'></div>
            </div>
            <div className='sub-card04-txt'>
              <h3 className='text-gold-seg'>Manicure</h3>
              <p> Consultas sempre preenchidas, menos cancelamentos e uma manicure movimentada todos os dias. uma manicure cheia, sem esforço, e com a tecnologia que trabalha por você!</p>
            </div>
            
          </div>
          <div className='card05-cover'>
            <div className='card05'>
              <div className='sub-card05'></div>
            </div>
            <div className='sub-card05-txt'>
              <h3 className='text-gold-seg'>Design de sobrancelha</h3>
              <p>Nosso sistema faz tudo por você: organiza os horários automaticamente, Uma agenda cheia, mais clientes fidelizadas, sem esquecimentos.Mais lucros, e tudo isso com apenas um clique!</p>
            </div>
            
          </div>
          <div className='card06-cover'>
            <div className='card06'>
              <div className='sub-card06'></div>
            </div>
            <div className='sub-card06-txt'>
              <h3 className='text-gold-seg'>Lanchonete</h3>
              <p>  reservando mesas e horários de forma rápida e fácil pelo WhatsApp e ainda poder fazer pedidos de delivery, sem nenhuma complicação. Evite sobrecargas e aumente suas vendas
                 </p>
            </div>
            
          </div>

        </div>
      </section>
  );
};

export default Segmento;