import React from 'react';

const Contato = () => {
  return (
    <section id='contatos' className='contatos'>
      <h1>Fale conosco!</h1>
      <div className='content-contatos'>
        <p>Natal - RN</p>
        <p>Telefone: <a href="https://api.whatsapp.com/send/?phone=%2B996354583&text&type=phone_number&app_absent=0">(84) 99635-4583 </a> </p>
        <p>E-mail: comercial@agendast.com.br</p>
        
      
      </div>

      <br></br>
      <br></br>

      <footer>
        <p>© 2024 Agendast. Todos os direitos reservados.<a href="https://www.developeraction.com.br"> Desenvolvido por Developer Action.</a>
       </p>
      </footer>

    </section>

  );
};

export default Contato;