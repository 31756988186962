import React,{ useState } from 'react';
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";

const Bonus = () => {

    const [indice, setIndice] = useState(0);

    const slides = [
        { title: 'História', text: `Sou Lairton Rodrigues, Cabeleireiro Profissional há mais de 10 anos atuante na área da Beleza. 
                                    Trabalhar com cabelos sempre foi meu sonho, porém parecia algo impossível aos meus olhos. 
                                    Mas sabe quando você nasce determinado a vencer na vida? Sabe quando você confia em si mesmo e vai à frente? 
                                    Pois bem! Esse sou eu, um jovem determinado a buscar coisas impossíveis e torná-las realidade. Hoje sinto-me 
                                    preparado para ajudar você a transformar sua vida profissional, assim como eu alavanquei minha carreira!` },

        { title: 'Conquistas', text: `Durante minha carreira profissional, tive a oportunidade de ministrar cursos em mais de 22 estados, 
                                    incluindo Maceió-AL, João Pessoa-PB, Fortaleza-CE, Salvador-BA, Natal-RN, entre outros. Confesso que a 
                                    cada curso ministrado, sinto a sensação de dever cumprido e de que estamos no caminho certo!` },

        { title: 'Prêmios', text: `Tive a honra de ser premiado em uma das maiores premiações, o Tesouro de Ouro, como Revelação 
                                    Destaque da Beleza, prêmio que me tornou reconhecido em todo o estado do Rio Grande do Norte. Convido você, 
                                    profissional da área da beleza, a viver o extraordinário e buscar o novo. Lembre-se: o conhecimento liberta, 
                                    e uma vez adquirido, ninguém pode tirar de você.` },
    ];

    const nextSlide = () => {
        setIndice(indice+1);
        if(indice === slides.length-1){
        setIndice(0);
        }
    };

    const prevSlide = () => {
        setIndice(indice-1);
        if(indice === 0){
        setIndice(slides.length-1);
        }
    };

    return (
        <section id='bonus' className='bonus'>
            <div className='content-bonus'>
            <div className='content-bonus-01'>

            </div>
            <div className='content-bonus-02'>
                <div className="slide">
                <button className='button01' onClick={prevSlide}><GrFormPrevious size={30}/></button>
                <div className="slide-content">
                    <h2 className='text-gold'>{slides[indice].title}</h2>
                    <p>{slides[indice].text}</p>
                </div>
                <button className='button02' onClick={nextSlide}><GrFormNext size={30}/></button>
                </div>
            </div>
            </div>
        </section>
    );
};

export default Bonus;