import { useState } from 'react';
import './App.css';
import { IoChevronUp } from "react-icons/io5";


import Home from './Componentes/home';
import Sobre from './Componentes/sobre';
import Segmento from './Componentes/segmento';
import Bonus from './Componentes/bonus';
import Contato from './Componentes/contato';

function App() {

  const [back, setBack] = useState(true);

  return (
    <div className='main'>

      {back?<a href='#home' onClick={()=>setBack(false)} className='back'><IoChevronUp />
      </a>:null}

      <Home setBack={setBack}/>
      <Sobre/>
      <Segmento/>
      <Bonus/>
      <Contato/>
      
    </div>
  );
}

export default App;
