import React from 'react';
import logotipo from '../Assets/logo/img01.png';


const Home = ({ setBack }) => {
  return (
    <section id='home' className='home'>
      <div className='navigation'>
        {/* Logotipo */}
        <img
          src={logotipo}
          alt="Logotipo"
          className='logo-image'
        />
        {/* <p className='logo'>AGENDAST</p> */}
        <div className='menu'>
          <a href='#home' >HOME</a>
          <a onClick={() => setBack(true)} href='#sobre' >SOBRE</a>
          <a onClick={() => setBack(true)} href='#segmentos' >SEGMENTOS</a>
          <a onClick={() => setBack(true)} href='#bonus' >BÔNUS</a>
          <a onClick={() => setBack(true)} href='#contatos' >CONTATOS</a>


        </div>

      </div>
      <div className='content'>
        <div className='content-text'>
          <h1>Faça seus <span className='text-gold'>Agendamentos e Pedidos </span> <br />
            De Maneira <span className='text-gold'>Simples</span><br /> e
            <span className='text-gold'> Eficiente!</span>
          </h1>
          <hr className='line'></hr>
          <p> Gerencie seus compromissos, organize horários,
            notifique clientes, delegue responsabilidades e
            acompanhe todos os detalhes com facilidade.
            Controle sua agenda e <span className='text-gold'>maximize sua produtividade
            de forma inovadora e integrada diretamente ao Whatsapp do seu negócio. </span></p>
        </div>
        <div className='content-img'>
          <div></div>
        </div>
      </div>
    </section>
  );
};

export default Home;